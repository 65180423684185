import styled from '@emotion/styled';
import { Box, FormControl, InputLabel, MenuItem, Select, Grid } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
// import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useState } from 'react';

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '30px',
  display: 'flex',
  alignItems: 'center',
}));

export default function SwitchLanguage() {
  // Translated page
  const { t, i18n } = useTranslation('common');
  const [currentLang, setCurrentLang] = useState(i18n.language);

  const handleChangeLang = e => {
    document.cookie = `language=${e.target.value};path=/`;
    i18n.changeLanguage(e.target.value);
    console.log({ lang: i18n.language });
    setCurrentLang(e.target.value);
    window.location.reload();
  };

  const availableLanguages = [
    {
      id: 1,
      name: t('languages.english'),
      imageSrc: '/images/languages/english.svg',
      value: 'en',
    },
    {
      id: 2,
      name: t('languages.spanish'),
      imageSrc: '/images/languages/spain.svg',
      value: 'es',
    },
    {
      id: 2,
      name: t('languages.french'),
      imageSrc: '/images/languages/french.svg',
      value: 'fr',
    },
  ];


  return (
    <>
      <Box sx={{ width:'100%'}}>
        <FormControl sx={{ m: 1, minWidth: 80, width:'90%' }}>
          <InputLabel sx={{ color: 'white' }} id='demo-simple-select-autowidth-label'>
            {t('languages.label')}
          </InputLabel>
          <StyledSelect
            variant='outlined'
            labelId='demo-simple-select-autowidth-label'
            id='demo-simple-select-autowidth'
            value={currentLang}
            onChange={handleChangeLang}
            autoWidth
            label={t('languages.label')}
          >
            <MenuItem disabled value=''>
              <em>Language</em>
            </MenuItem>
            {availableLanguages.map((lang, i) => (
              <MenuItem key={i} value={lang.value}>
                <Grid alignItems='center' container spacing={2}>
                  <Grid item xs={4}>
                    <Image src={lang.imageSrc} alt={lang.imageSrc} width={30} height={30} />
                  </Grid>
                  <Grid item xs={4}>
                    {lang.name}
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        {/* <select name='lang' id='lang' value={currentLang} onChange={handleChangeLang}>
          {availableLanguages.map((lang, i) => (
            <opt key={i} value={lang.value}>
              {lang.name}
            </opt>
          ))}
        </select> */}
      </Box>
    </>
  );
}
