import axios from 'axios';

const API_URL = process.env.NEXT_PUBLIC_API_URL;
// const API_KEY = process.env.NEXT_PUBLIC_API_KEY;

export const axiosBase = async (URL, method, body = null, apiKey) => {
  const url = `${API_URL}/${URL}`;

  switch (method) {
    case 'POST':
      try {
        const response = await axios.post(url, body, { headers: { Authorization: apiKey } });

        if (response.status === 401) {
          localStorage.clear();
          window.location.reload();
          alert('Session expired');
        } else {
          return response;
        }
      } catch (error) {
        window.location.reload();
        alert('Session expired, login again please');
        console.log(error);
      }
      break;
    case 'PUT':
      try {
        const response = await axios.put(url, body, { headers: { Authorization: apiKey } });
        if (response.status === 401) {
          localStorage.clear();
          window.location.reload();
          alert('Session expired');
        } else {
          return response;
        }
      } catch (error) {
        console.log(error);
      }
      break;

    case 'GET':
      try {
        const response = await axios.get(url, { headers: { Authorization: apiKey } });
        if (response.status === 401) {
          localStorage.clear();
          window.location.reload();
          alert('Session expired');
        } else {
          return response;
        }
      } catch (error) {
        console.log(error);
      }
      break;

    case 'DELETE':
      try {
        const response = await axios.delete(url, { headers: { Authorization: apiKey } });
        if (response.status === 401) {
          localStorage.clear();
          window.location.reload();
          alert('Session expired');
        } else {
          return response;
        }
      } catch (error) {
        console.log(error);
      }
      break;
    default:
      break;
  }
};
