import { Box, CircularProgress } from '@mui/material';
import Image from 'next/image';

export default function Loader() {
  return (
    <>
      <Box
        sx={{
          minHeight: '100vh',
          minWidth: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <>
          <Box sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box>
                <Image
                  src='/images/graphics/mainLogo.png'
                  alt='mainLogo'
                  width={300}
                  height={120}
                />
              </Box>
              <Box>
                <CircularProgress thickness={6} color='primary' size={80} />
              </Box>
            </Box>
          </Box>
        </>
      </Box>
    </>
  );
}
