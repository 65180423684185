const FxIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='210mm'
    height='210mm'
    viewBox='0 0 210 210'
    {...props}
  >
    <path
      d='M6.673 208.781c-8.187-3.378-8.994-13.378-1.571-19.463 2.54-2.083 3.937-1.884 9.857 1.402 8.43 4.68 14.761 4.882 18.69.594 1.63-1.779 4.571-9.444 6.536-17.033C43.759 160.478 58.17 78.595 58.17 72.09c0-2.954-1.283-3.447-8.976-3.447-4.937 0-8.977-.659-8.977-1.464 0-.805 1.943-3.267 4.318-5.471 3.072-2.85 5.991-3.835 10.113-3.412 5.676.582 5.872.359 9.595-10.932C69.401 31.72 76.44 20.41 85.558 13.116 99.69 1.81 112.355-2.327 121.884 1.252c9.32 3.5 10.893 10.98 3.712 17.644l-4.385 4.069-6.796-5.533c-8.196-6.674-15.526-7.252-19.325-1.526-2.933 4.421-9.956 31.547-9.982 38.552-.016 4.336.238 4.42 11.656 3.839 11.97-.61 13.39.257 9.354 5.71-1.593 2.152-5.696 3.436-13.092 4.098l-10.772.965-4.086 22.75c-10.036 55.883-19.728 83.52-35.28 100.606-13.027 14.312-26.463 20.38-36.215 16.355Zm96.509-54.37c-4.249-5.335-4.343-5.894-2.012-11.951 2.608-6.775 8.047-8.942 13.533-5.392 4.133 2.675 4.867 2.683 8.873.09 4.797-3.104 15.981-15.786 20.834-23.623 2.996-4.84 2.92-5.502-2.528-21.837-6.451-19.345-8.972-21.12-17.264-12.153-4.993 5.4-5.613 5.618-8.33 2.935-2.715-2.683-2.081-3.76 8.521-14.452 18.814-18.974 24.986-17.828 33.067 6.141 2.593 7.69 4.976 13.982 5.295 13.982.319 0 4.771-5.585 9.894-12.41 10.164-13.544 16.698-19.008 24.536-20.521 10.828-2.09 16.515 9.415 8.923 18.052-3.953 4.496-4.958 4.854-8.185 2.909-8.456-5.094-14.867-1.477-27.604 15.574l-4.68 6.264 5.09 15.902c9.254 28.916 10.957 30.572 21.643 21.036 6.684-5.965 10.799-6.687 10.799-1.895 0 3.792-15.812 20.135-22.525 23.282-12.72 5.962-18.033 1.326-25.916-22.618l-4.75-14.431-7.623 11.087c-18.653 27.132-31.103 34.688-39.591 24.029z'
      style={{
        fill: '#000',
        strokeWidth: 1.78425,
      }}
    />
  </svg>
);
export default FxIcon;
