import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { theme } from '@/utils/theme';
import IconButton from '@mui/material/IconButton';
import { Icon, SwipeableDrawer, Container, Menu, MenuItem } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import GroupIcon from '@mui/icons-material/Group';
// import LiveTvIcon from '@mui/icons-material/LiveTv';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import EarningsIcon from '@mui/icons-material/AttachMoney';
import FxIcon from './FxIcon';
// import StoreIcon from '@mui/icons-material/Store';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import PersonIcon from '@mui/icons-material/Person';
import Image from 'next/image';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { Logout } from '@mui/icons-material';
import SwitchLanguage from '@/components/SwitchLanguage';
import { useTranslation } from 'react-i18next';
import Loader from '@/components/Loader';
import VIPImage from '../../../public/images/vip.png';

const StyledListItem = styled(ListItem)(({ current, theme }) => ({
  width: '90%',
  margin: 'auto',
  marginTop: '.5rem',
  backgroundColor: current && 'rgba(255, 255, 255, 0.3)',
  borderRadius: '.5rem',
}));

const BoxDesktop = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
    visibility: 'hidden',
  },
}));

const BoxMobile = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
    visibility: 'hidden',
  },
}));

const drawerWidth = 240;

function getGreeting() {
  const now = new Date();
  const currentHour = now.getHours();

  if (currentHour < 12) {
    return 'Morning';
  } else if (currentHour < 18) {
    return 'Afternoon';
  } else {
    return 'Evening';
  }
}

export default function PermanentDrawerLeft({ children, session }) {
  // function that translates the page
  const { t } = useTranslation('common');

  const roles = {
    user: ['user'],
    admin: ['user', 'admin'],
  };

  const itemList = [
    { name: t('toolbar.items.dashboard'), link: '/', icon: <LeaderboardIcon />, rol: roles.user },
    {
      name: t('toolbar.items.earnings'),
      link: '/earnings',
      icon: <EarningsIcon />,
      rol: roles.user,
    },
    { name: t('toolbar.items.profile'), link: '/profile', icon: <PersonIcon />, rol: roles.user },
    {
      name: t('toolbar.items.reports'),
      link: '/reports',
      icon: <InsertChartIcon />,
      rol: roles.user,
    },
    { name: t('toolbar.items.users'), link: '/admin/users', icon: <GroupIcon />, rol: roles.admin },
    { name: 'MitoxLive', link: '/mitox-live', icon: <LiveTvIcon />, rol: roles.user },
    {
      name: 'MitoxCard',
      link: 'https://cliente.mysafexcard.com/',
      icon: <CreditCardIcon />,
      rol: roles.user,
    },
    {
      name: t('toolbar.items.courses'),
      link: '/courses',
      icon: <SchoolRoundedIcon />,
      rol: roles.user,
    },
    {
      name: t('toolbar.items.network'),
      link: '/network',
      icon: <AccountTreeRoundedIcon />,
      rol: roles.user,
    },
  ];

  const itemFxBook = [
    {
      id: 1,
      name: 'Sistema 1',
      link: 'https://www.myfxbook.com/portfolio/mtxpro-v/10252431',
    },
    {
      id: 2,
      name: 'Sistema 2',
      link: 'https://www.myfxbook.com/portfolio/mtx-pro-j/10265289',
    },
  ];

  const itemListAdmin = itemList.filter(
    item => item.rol === roles.user || item.rol === roles.admin
  );
  const itemListUser = itemList.filter(item => item.rol === roles.user);

  const router = useRouter();
  const [selectedItem, setSelectedItem] = useState(itemList[0]);
  const [openDrawer, setOpenDrawer] = useState(false);
  // const [state, setState] = useState({
  //   right: false,
  // });
  const [itemsDashboard, setItemsDashboards] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = Boolean(anchorEl);
  const handleMenuClick = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = e => {
    setAnchorEl(null);
  };

  const toggleOpenDrawer = e => {
    setOpenDrawer(!openDrawer);
  };

  const handleLogout = () => {
    localStorage.clear();
    router.push('/auth/login');
  };

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('sessionMitox'));
    const items = session.user.rol === 0 ? itemListUser : itemListAdmin;
    setItemsDashboards(items);
  }, []);

  useEffect(() => {
    const currentItem = itemList.find(item => item.link === router.pathname);
    setSelectedItem(currentItem);
  }, [router.pathname]);

  if (!itemsDashboard) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <>
      <BoxDesktop sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position='fixed'
          sx={{
            borderBottom: '1px solid #E9E9E9',
            boxShadow: 'none',
            backgroundColor: 'white',
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
          }}
        >
          <Toolbar>
            <Box
              sx={{
                py: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box>
                <Typography
                  sx={{ fontWeight: 600 }}
                  color='rgba(51, 52, 61, 1)'
                  variant='h4'
                  component='div'
                >
                  Good {getGreeting()}, {session.user.firstname}
                </Typography>
                <Typography variant='body2' color={theme.palette.text.disabled}>
                  Welcome back,nice to see you again!
                </Typography>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              backgroundImage: 'url("/images/graphics/dashboard/side-decorator.svg")',
              backgroundSize: 'contain',
              backgroundRepeat: 'repeat',
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: '#EC6523',
            },
          }}
          variant='permanent'
          anchor='left'
        >
          <Image
            style={{
              width: '100%',
              marginTop: '1rem',
            }}
            src='/images/graphics/mainLogoWhite.svg'
            alt='mainLogoWhite'
            width={200}
            height={50}
          />
          <Toolbar />

          <List sx={{ color: 'white' }}>
            <StyledListItem disablePadding>
              <SwitchLanguage />
            </StyledListItem>
            {itemsDashboard.map((item, index) => (
              <StyledListItem current={selectedItem?.link === item.link} key={index} disablePadding>
                <ListItemButton
                  onClick={() => {
                    item.name === 'Logout' ? handleLogout() : router.push(item.link);
                  }}
                >
                  <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </StyledListItem>
            ))}
            <StyledListItem disablePadding>
              <ListItemButton
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleMenuClick}
              >
                <ListItemIcon>
                  <Image src='/images/graphics/fx.svg' alt='fx' width={20} height={20} />
                </ListItemIcon>
                <ListItemText>MyFxBook</ListItemText>
              </ListItemButton>
              <Menu anchorEl={anchorEl} open={openMenu} onClose={handleMenuClose}>
                {itemFxBook.map((item, i) => (
                  <>
                    <MenuItem
                      sx={{ width: '200px', display: 'flex', justifyContent: 'center' }}
                      onClick={() => {
                        window.open(item.link, '_blank');
                      }}
                    >
                      <Typography variant='body1' color='textPrimary' fontWeight={600}>
                        {item.name}
                      </Typography>
                    </MenuItem>
                  </>
                ))}
              </Menu>
            </StyledListItem>
            <StyledListItem disabledPadding>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon sx={{ color: 'white' }}>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary={t('toolbar.items.logout')} />
              </ListItemButton>
            </StyledListItem>
          </List>
        </Drawer>
        <Container
          maxWidth='lx'
          fullWidth
          component='main'
          sx={{
            bgcolor: 'background.default',
            p: 0,
            py: 5,
            m: 0,
            overflowX: 'hidden',
            width: '100%',
          }}
        >
          <Toolbar />
          {children}
        </Container>
      </BoxDesktop>

      {/* MOBILE */}
      <BoxMobile>
        <AppBar
          sx={{
            backgroundColor: 'white',
            py: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <IconButton size='large'>
                <Icon>
                  <PersonIcon />
                </Icon>
              </IconButton>
            </Box>
            <Box>
              <IconButton size='large' onClick={toggleOpenDrawer}>
                <Icon sx={{ color: 'black' }}>
                  <MenuIcon />
                </Icon>
              </IconButton>
              <SwipeableDrawer
                sx={{
                  // width: '100%',
                  width: drawerWidth,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    backgroundImage: 'url("/images/graphics/dashboard/side-decorator.svg")',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'repeat',
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: '#EC6523',
                  },
                }}
                anchor='right'
                open={openDrawer}
                onClose={toggleOpenDrawer}
                onOpen={toggleOpenDrawer}
              >
                <Container sx={{ width: '100%' }} maxWidth='lg'>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      pt: 2,
                    }}
                  >
                    <IconButton onClick={toggleOpenDrawer}>
                      <Icon sx={{ color: 'white' }}>
                        <MenuOpenIcon />
                      </Icon>
                    </IconButton>
                    <Image
                      src='/images/graphics/mainLogoWhite.svg'
                      alt='mainLogoWhite'
                      width={200}
                      height={40}
                    />
                  </Box>
                  {/* <Toolbar> */}
                  <List sx={{ color: 'white' }}>
                    <StyledListItem disablePadding>
                      <SwitchLanguage />
                    </StyledListItem>
                    {itemsDashboard.map((item, index) => (
                      <StyledListItem
                        current={selectedItem?.link === item.link}
                        key={index}
                        disablePadding
                      >
                        <ListItemButton onClick={() => router.push(item.link)}>
                          <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.name} />
                        </ListItemButton>
                      </StyledListItem>
                    ))}
                    <StyledListItem disablePadding>
                      <ListItemButton
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleMenuClick}
                      >
                        <ListItemIcon>
                          <Image src='/images/graphics/fx.svg' alt='fx' width={20} height={20} />
                        </ListItemIcon>
                        <ListItemText>MyFxBook</ListItemText>
                      </ListItemButton>
                      <Menu anchorEl={anchorEl} open={openMenu} onClose={handleMenuClose}>
                        {itemFxBook.map((item, i) => (
                          <>
                            <MenuItem
                              sx={{ width: '200px', display: 'flex', justifyContent: 'center' }}
                              onClick={() => {
                                window.open(item.link, '_blank');
                              }}
                            >
                              <Typography variant='body1' color='textPrimary' fontWeight={600}>
                                {item.name}
                              </Typography>
                            </MenuItem>
                          </>
                        ))}
                      </Menu>
                    </StyledListItem>
                    <StyledListItem disabledPadding>
                      <ListItemButton>
                        <ListItemIcon sx={{ color: 'white' }} onClick={handleLogout}>
                          <Logout />
                        </ListItemIcon>
                        <ListItemText primary={t('toolbar.items.logout')} />
                      </ListItemButton>
                    </StyledListItem>
                  </List>
                  {/* </Toolbar> */}
                </Container>
              </SwipeableDrawer>
            </Box>
          </Box>
        </AppBar>
        <Box
          sx={{
            mt: 10,
            mx: 1,
          }}
        >
          {children}
        </Box>
      </BoxMobile>
    </>
  );
}
